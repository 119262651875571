<template>
	<div class="main">
		<div class="banner-content">
			<div class="banner">
				<img style="object-fit: none;height: 400px;width: 1920px;" src="@/assets/images/banner/app.png" alt="" />
			</div>

		</div>
		<div style="display: flex;justify-content: center;background: #F6F6F6;margin-top: 100px;">
			<div class="content">
				<div class="content-bottom">
					<div class="content-bottom-QRCode"><img src="../../assets/images/ios07.png" /></div>
					<div style="height: 100px;display: flex;flex-direction: column;justify-content: space-between;">
						<div class="ios_down"></div>
						<div class="andro_down"></div>
					</div>
				</div>
			</div>
		</div>
		<div style="height: 100px"></div>
	</div>
</template>

<script>
	import http from "@/common/utils/Http";
	export default {
		metaInfo: {
			title: "鑫筑云建APP下载-鑫昇腾",
			meta:[
				{name:"keywords", content: "鑫筑云建,智能爬架技术服务,智能爬架物联平台,智能爬架运营"},
				{name:"description", content: "鑫筑云建物联平台使用数字化平台运营模式,实现爬架可控、可视化管理,远程操控爬架,帮助用户进行资产运营管理、 项目管理、应收款管理。"}
			]
		},
		data() {
			return {
				app: {},
				total: 0,
				route: this.$route,
				params: {
					pageNum: 1,
					pageSize: 12
				},
			};
		},
		activated() {
			this.getApp();
		},
		methods:{
			getApp() {
				let params = this.params;
				http.api.get("/site/appDown/list", {
					params
				}).then((res) => {
					if(res.data.rows){
						this.app = res.data.rows[0];
					}
					
					this.total = res.data.total
				});
			},
			downIos(){
				window.open(this.app.iosDownUrl)
			},
			downan(){
				window.open(this.app.anDownUrl)
			}
		}
		
	};
</script>

<style lang="scss" scoped>
	.main{
		background: #F6F6F6;
		div, a{font-family: MicrosoftYaHei;}
	}
	.app-back {
		background: url('../../assets/images/app/组 59@2x.png');
	}
	.banner-content {
		position: relative;
		height: 400px;
		width: 100%;
	}

	.banner {
		height: 100%;
		width: 100% !important;
		overflow: hidden;
		text-align: center;
		margin: 0 auto;
		padding: 0;
		z-index: 1;
	}

	.banner img {
		width: auto;
		margin: 0 -100%;
		max-width: none;
	}
	.content {
		height: 665px;
		width: 1200px;
		background-image: url(../../assets/images/app/appdownload-bg.png);
		position: relative;
	}
	.content-bottom {
		position: absolute;
		left: 60px;
		top: 440px;
		display: flex;
		width: 320px;
		justify-content: space-between;
	}
	.content-bottom-QRCode{
		width: 100px;
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #333;

		img {
			width: 90px;
			height: 90px;
		}
	}
	.ios_down{
		width: 193px;
		height: 40px;
		background-image: url(../../assets/images/app/IOSdownload.png);
	}
	.andro_down{
		width: 193px;
		height: 40px;
		background-image: url(../../assets/images/app/Androiddownload.png);
	}
</style>
